import Title from "../../../components/Title";
import "./styles.css";

const text =
  "Somos un equipo de profesionales y entusiastas tecnológicos comprometidos con la evolución digital. Apoyamos tu negocio a digitalizar y agilizar sus procesos logrando así la transformación digital.";

const About: React.FC<any> = () => {
  return (
    <section className="about-section">
      <img src="/assets/bg/about2.png" alt="bg" className="bg-image" />
      <div className="wrapper__text__about">
        <Title
          color="navyBlue"
          fontSize={"LG"}
          label="¿Qué es WSD?"
          bold
          topLine
          id="about"
        />
        <span className="text__wrapper_about">{text}</span>
      </div>
    </section>
  );
};

export default About;
