import { loadFull } from "tsparticles";

import Particles from "react-tsparticles";
import "./styles.css";
const ParticlesWSD = () => {

  const particlesInit = async (main: any) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container: any): any => {
  };
  return (
    <Particles
      className={"wrapper-particles"}
      canvasClassName={"canvas-particles"}
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        style: { position: "relative", backgroundColor: "red" },
        preset: "snow",
        fpsLimit: 120,
        particles: {
          opacity: {
            value: 0.7,
          },
          number: {
            density: {
              enable: true,
              area: 500,
            },
            value: 30,
          },
          collisions: {
            enable: true,
          },
          links: {
            enable: false,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: true,
            speed: 1,
            straight: true,
          },
          shape: {
            type: ["image", "circle"],
            options: {
              images: [
                {
                  src: "/assets/icons/S3.png",
                  height: 1000,
                  width: 1000,
                },
              ],
            },
          },
          color: {
            value: "#319DED",
          },
          size: {
            value: { min: 1, max: 30 },
          },
        },
        detectRetina: false,
      }}
    />
  );
};

export default ParticlesWSD;
