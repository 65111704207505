import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import NavbarItem from "../NavbarItem";
import "./styles.css";

const navItems = [
  {
    title: "NOSOTROS",
    locationHref: "#about",
  },
  {
    title: "SERVICIOS",
    locationHref: "#services",
  },
  {
    title: "TECNOLOGÍA",
    locationHref: "#tech",
  },
  {
    title: "PORTAFOLIO",
    locationHref: "#testimonials",
  },
  {
    title: "CONTACTO",
    locationHref: "#contact",
  },
];

const NavbarWSD: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => setIsOpen(!isOpen);
  return (
    <Navbar className="my-navbar" expand="md" light>
      <NavbarBrand href="/" className="container-logo">
        <img
          src="/assets/img/WSD_logo.svg"
          className="logo-navbar"
          alt={"logo navbar"}
        />
      </NavbarBrand>
      <NavbarToggler onClick={onToggle} style={{border: "1px solid #ccc"}}/>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          {navItems.map((element, index) => {
            return (
              <NavbarItem
                itemTitle={element.title}
                itemLoc={element.locationHref}
                key={index}
                toggle={onToggle}
              />
            );
          })}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarWSD;
