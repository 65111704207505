import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import Title from "../../../components/Title";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "./styles.css";
SwiperCore.use([Pagination, Navigation, Autoplay]);

const data = [
  {
    imgURI: "assets/tech/ionic.svg",
    label: "Ionic",
  },
  {
    imgURI: "assets/tech/react.svg",
    label: "React",
  },
  {
    imgURI: "assets/tech/azure.svg",
    label: "Azure",
  },
  {
    imgURI: "assets/tech/aws.svg",
    label: "AWS",
  },
  {
    imgURI: "assets/tech/js.svg",
    label: "Javascript"
  },
  {
    imgURI: "assets/tech/net-core.svg",
    label: "Net Core"
  },
  {
    imgURI: "assets/tech/python.svg",
    label: "Python"
  },
  {
    imgURI: "assets/tech/mysql.svg",
    label: "MySQL"
  },
  {
    imgURI: "assets/tech/sqlserver.svg",
    label: "SQL Server"
  }
];

const Tech: React.FC<any> = () => {
  return (
    <section className="tech-section">
      <Title color="blue" fontSize={"LG"} label="TECNOLOGIAS" topLine id="tech"/>
      <div className="wrapper__swiper__tech">
        <Swiper
          slidesPerView={3}
          spaceBetween={0}
          navigation={true}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            "320": {
              "slidesPerView": 1,
              "spaceBetween": 0,
            },
            "640": {
              "slidesPerView": 1,
              "spaceBetween": 0,
            },
            "768": {
              "slidesPerView": 2,
              "spaceBetween": 10,
            },
            "1024": {
              "slidesPerView": 3,
              "spaceBetween": 10,
            },
            "1600": {
              "slidesPerView": 3,
              "spaceBetween": 0,
            },
          }}
          pagination={{
            clickable: true,
          }}
          className="swiper-tech"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div key={index} className="contanier-img-tech">
                  <div className="card-swiper-tech">
                    <img src={item.imgURI} alt="icon" className="img-tech" />
                    {item.label && <span className="label-tech">{item.label}</span>}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Tech;
