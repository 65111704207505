import "./styles.css"
const Footer = () => {
    const scrollIinit = () => {
        window.scrollTo(0, 0);
    }

    const showPolicity = () => {
        window.open('assets/pdf/WSD_-_Privacy_Policy.pdf')
    }

    return (
        <footer className="footer-section">
            <div className="wrapper__info">
                <span className="label-info-footer link-to-init" onClick={scrollIinit}>Inicio</span>
                <span className="label-info-footer"> &#183; </span>
                <span className="label-info-footer name-company">Wit Services &#38; Development &#174;</span>
            </div>
            <div className="footer-bottom">
                <span className="links-footer" onClick={showPolicity}>Politicas de privacidad</span>
            </div>
        </footer>
    )
}

export default Footer
