import NavbarWSD from "../../components/NavbarWSD";
import useRefNavigation from "../../hooks/useRefNavigation";
import About from "../../ViewComponents/Home/About";
import ContactUs from "../../ViewComponents/Home/ContactUs";
import Footer from "../../ViewComponents/Home/Footer";
import HeroSection from "../../ViewComponents/Home/HeroSection";
import Services from "../../ViewComponents/Home/Services";
import Tech from "../../ViewComponents/Home/Tech";
import Testimonials from "../../ViewComponents/Home/Testimonials";
import "./styles.css";
const Home = () => {
  const { refContact } = useRefNavigation();
  return (
    <>
      <header>
        <NavbarWSD />
      </header>
      <main className="home__wrapper">
        <HeroSection refContact={refContact} />
        <About />
        <Services />
        <Tech />
        <Testimonials />
        <ContactUs refContact={refContact} />
        <Footer />
      </main>
    </>
  );
};

export default Home;
