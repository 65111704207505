import { useState } from "react";
import ButtonWSD from "../../../components/ButtonWSD";
import InputWSD from "../../../components/InputWSD";
import ModalWSD from "../../../components/ModalWSD";
import Title from "../../../components/Title";
import "./styles.css";

import { sendEmail } from "../../../services/contact";
import Loading from "../../../components/Loading/Loading";

interface contactUsProps {
  refContact: React.RefObject<HTMLDivElement>;
}

const links = [
  {
    uriIcon: "assets/contact/mail.svg",
    label: "E-mail",
    contact: "contact@wsd.com.mx",
  },
  {
    uriIcon: "assets/contact/phone.svg",
    label: "Comunicate",
    contact: "(+52) 3334644819",
  },
];

const ContactUs: React.FC<contactUsProps> = ({ refContact }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");

  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setName("");
      setEmail("");
      setPhone("");
      setComment("");
    try {
      const data:any = {
        "Name": name,
        "Email": email,
        "PhoneNumber": phone,
        "Message": comment,
      }
      await sendEmail(data);
      setIsLoading(false);
    } catch(error) {
      console.error(error)
      setIsLoading(false);
    }
    toggle();
  };

  const formData = [
    {
      name: "name",
      label: "Nombre:",
      type: "text",
      value: name,
      onChange: ({ target: { value } }: any) => setName(value),
      isInput: true,
      iconInput: "/assets/icons/user.svg",
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: email,
      onChange: ({ target: { value } }: any) => setEmail(value),
      isInput: true,
      iconInput: "/assets/icons/mail.svg",
    },
    {
      name: "phone",
      label: "Teléfono:",
      type: "number",
      value: phone,
      onChange: ({ target: { value } }: any) => setPhone(value),
      isInput: true,
      iconInput: "/assets/icons/phone.svg",
    },
    {
      name: "comment",
      label: "Comentario:",
      type: "text",
      value: comment,
      onChange: ({ target: { value } }: any) => setComment(value),
      isInput: false,
    },
  ];

  const toggle = () => setIsShow(!isShow);

  return (
    <>
      {isLoading && <Loading />}
      <ModalWSD isShow={isShow} toggle={toggle} />
      <section className="contact-us-section" ref={refContact}>
        <div className="wrapper__form_contact">
          <div className="wrapper__titile__contact">
            <Title
              color="blue"
              fontSize="LG"
              id="contact"
              label="CONTACTA CON NOSOTROS"
              underLine
            />
          </div>
          <div className="container__wrapper__form">
            <form className="form__contact" onSubmit={handleSubmit}>
              {formData.map((item, index) => {
                return <InputWSD key={index} {...item} />;
              })}
              <ButtonWSD
                labelButton="Enviar"
                handleClick={() => {}}
                size="LG"
                type="submit"
              />
            </form>

            <div className="container__contact-us">
              <div className="container__contact-us__info">
                {links.map((item, index) => {
                  return (
                    <div className="row__container-us" key={index}>
                      <div className="column__container-us">
                        <img
                          src={item.uriIcon}
                          alt={"Icon contact"}
                          className="img-contact"
                        />
                        <span className="img__label">{item.label}</span>
                      </div>
                      <div className="container__contact__label">
                        <span className="contact-label">{item.contact}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
