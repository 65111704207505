import React from "react";
import "./styles.css";
interface cardTestimonialsProps {
  imgClient?: string;
  project: string;
  link?: string;
  testimonial: string;
  clientName: string;
}

const CardTestimonials: React.FC<cardTestimonialsProps> = ({
  imgClient = "",
  project,
  link,
  testimonial,
  clientName,
}) => {
  return (
    <div className="card-testimonials">
      <div className="bcard-testimonials">
        <div className="wrapper__img_testimonials">
          {imgClient !== "" ? (
            <div className="container-img-client">
              <img src={imgClient} alt="icon client" className="img-client" />
            </div>
          ) : (
            <div className="container-img-client">
              <img
                src="assets/img/client.svg"
                alt="icon client"
                className="img-client"
              />
            </div>
          )}
        </div>
        <div className="wrapper__client">
          <h2 className="client-name-label">{clientName}</h2>
        </div>
        <div className="wrapper__project">
          <h4 className="project-label">{project}</h4>
        </div>
        <div className="wrapper__content">
          <img className="quotes" alt="quotes" src="/assets/icons/quotes.svg" />
          <span className="testimonials-content">
            {testimonial}
          </span>
          <img className="quotes rotate-quote" alt="quotes" src="/assets/icons/quotes.svg" />
        </div>
      </div>
      <div className="fcard-testimonials">
        <div className="wrapper__contact__client">
          <a href={link === "" ? "#" : link} className="btn-contact" style={{display: `${link === "" ? 'none': 'block'}`}}>
            Ir al Sitio
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardTestimonials;
