import { MouseEventHandler } from "react"
import "./styles.css"

interface ButtonWSDProps {
    labelButton: string,
    handleClick: MouseEventHandler<HTMLButtonElement>,
    size: string,
    type?: any,
  };

const sizesButton:any = {
    "LG": {
        width: "9em",
    },
    "MD": {
        width: "7em",
    }
}
const ButtonWSD: React.FC<ButtonWSDProps>= ({labelButton="", handleClick, size="", type="button"}) => {
    return (
        <button className="btn-action" type={type} style={sizesButton[size]} onClick={handleClick}>
            {labelButton}
        </button>
    )
}

export default ButtonWSD
