import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import CardTestimonials from "./components/CardTestimonials";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

import "./styles.css";
import Title from "../../../components/Title";

SwiperCore.use([Pagination, Navigation, Autoplay]);
const data = [
  {
    imgClient: "assets/testimonials/upkeep.png",
    project: "",
    clientName: "Upkeep",
    link: "https://upkeepfirm.com/home",
    testimonial: "Sistema para la contratación de servicios del hogar(Electricista, Plomeria, Jardineria, etc) disponible en Las Vegas, Nevada."
  },
  {
    imgClient: "assets/testimonials/move.jpg",
    project: "MoveApp",
    link: "https://move.mx/",
    clientName: "Move Wellness",
    testimonial:
      "Aplicación Móvil para los usuarios del club deportivo para consultar información de su subscripción, beneficios, etc",
  },
  {
    imgClient: "assets/testimonials/fitnance.png",
    project: "Sistema de Administración de la deuda pública de los entes públicos",
    link: "https://fitnance.com.mx/",
    clientName: "F-IT-Consultores",
    testimonial:
      "Permite gestionar de manera integral la deuda pública, conforme lo establece la Ley General de Contabilidad Gubernamental, como le Ley de Disciplina Financiera para los Estados y Municipios del País.",
  },
  {
    imgClient: "assets/testimonials/reel.png",
    project: "",
    link: "https://reel.mx/",
    clientName: "Reel",
    testimonial:
      "Desarrollo de web para agencia inmobiliaria, dedicada a la venta, renta y compra de casas, terrenos, locales comerciales y de cualquier tipo de inmueble",
  },
  {
    imgClient: "assets/img/client.svg",
    project: "",
    link: "",
    clientName: "Cuprum",
    testimonial:
      "Consultoria para el desarrollo de mejoras en sistema de precios para incluir sobrecargos",
  },
];

const Testimonials: React.FC<any> = () => {
  return (
    <section className="testimonials-section">
      <Title color="white" fontSize={"LG"} label="PORTAFOLIO" id="testimonials" />
      <div className="wrapper__swiper__testimonials">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
          }}
          navigation={true}
          className="swiper-testimonials"
        >
          {data.map((testimonial, index) => {
            return (
              <SwiperSlide key={index}>
                <CardTestimonials
                  key={index}
                  project={testimonial.project}
                  testimonial={testimonial.testimonial}
                  imgClient={testimonial.imgClient}
                  link={testimonial.link}
                  clientName={testimonial.clientName}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
