import { SERVER_URL } from "../env"



export const sendEmail = async (data: any) => {
    try {
        const response = await fetch(`${SERVER_URL}/contact`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        const json = await response.json()
        return json.data
    } catch (error) {
        throw error
    }
}


