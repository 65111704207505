import Home from "./Views/Home";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./theme/variables.css";
// import { useMobile } from "./hooks/useMobile";

const WSDApp = () => {
  return (
    <div className="App">
      <Home />
    </div>
  );
};

const App = () => {
  return (
      <WSDApp />
  );
};

export default App;
