import React from "react";
import "./styles.css";

interface InputWSDProps {
  name: string;
  label: string;
  type: string;
  value: string;
  onChange: any;
  isInput: boolean;
  iconInput?: string;
}

const InputWSD: React.FC<InputWSDProps> = ({
  name,
  label,
  type,
  onChange,
  isInput,
  iconInput,
  value,
}) => {
  return (
    <div className="form__contact__item">
      <label className="form__contact__item__label">{label}</label>
      {isInput ? (
        <div className="wrapper__input">
          <img src={iconInput} alt="icon" className="icon-input" />
          <input
            className="form-control-input form__contact__item__input"
            type={type}
            name={name}
            value={value}
            onChange={onChange}
          />
        </div>
      ) : (
        <div className="wrapper__input">
          <textarea
            className="form-control-input form__contact__item__textarea"
            name={name}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default InputWSD;
