import { useState } from "react";
import "./styles.css";
interface roundedProps {
  children: any,
  styles: Object,
  hover: Boolean,
  handleClick: any,
};

const Rounded: React.FC<roundedProps> = ({ children, styles, hover, handleClick }) => {

  const [isHover, setIsHover] = useState(false);
  const mouseOver = () => {
    if(hover) setIsHover(hover => !hover);
  }

  const mouseOut = () => {
    if(hover) setIsHover(hover => !hover);
  }

  return (
    <div
      className="rounded-icon"
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      onClick={handleClick}
      style={{...styles,opacity: isHover ? "0.7" : "1.0" }}
    >
      {children}
    </div>
  );
};

export default Rounded;
