import ButtonWSD from "../ButtonWSD";
import "./styles.css";
const ImgCard = ({ labelCard, imgURI }) => {
  return (
    <div className="container-card-services">
      <div className="card-services">
        <div className="cbody__wrapper__services">
          <img src={imgURI} alt="img card" className="img_card_services" />
        </div>
        <div className="cfooter__wrapper__services">
          <span className="label-card-services">{labelCard}</span>
        </div>
      </div>
      {/* <div className="contanier-btn">
        <ButtonWSD labelButton="Ver mas" handleClick={() => {}} size={"MD"} />
      </div> */}
    </div>
  );
};

export default ImgCard;
