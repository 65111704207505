import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import ImgCard from "../../../components/ImgCard";
import Title from "../../../components/Title";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "./styles.css";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

const services = [
  {
    imgURI: "assets/services/consultoria.svg",
    labelCard: "Consultoría en procesos tecnológicos.",
  },
  {
    imgURI: "assets/services/desarrollo1.png",
    labelCard: "Desarrollo de aplicaciones móviles",
  },
  {
    imgURI: "assets/services/desarrolloweb.svg",
    labelCard: "Desarrollo de sistemas web",
  },
  {
    imgURI: "assets/services/api.png",
    labelCard: "Desarrollo de interfaces de comunicaciones con sistemas externos",
  },
];

const Services = () => {

  return (
    <section className="services-section">
      <Title color="blue" fontSize={"LG"} label="Servicios" id="services"/>
      <div className="wrapper__swiper__services">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          navigation={true}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            "320": {
              "slidesPerView": 1,
              "spaceBetween":0
            },
            "640": {
              "slidesPerView": 1,
              "spaceBetween":0
            },
            "768": {
              "slidesPerView": 2,
              "spaceBetween": 10
            },
            "1024": {
              "slidesPerView": 3,
              "spaceBetween":10
            },
            "1600": {
              "slidesPerView": 3,
              "spaceBetween":0
            }
          }}
          className="mySwiper"
        >
          {services.map((service, index) => {
            return (
              <SwiperSlide key={index}>
                <ImgCard
                  key={index}
                  labelCard={service.labelCard}
                  imgURI={service.imgURI}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Services;
