import React from "react";

import ButtonWSD from "../../../components/ButtonWSD";
import ParticlesWSD from "../../../components/Particles";
import FacebookIcon from "../../../Utils/Icons/FacebookIcon";
import InstagramIcon from "../../../Utils/Icons/InstagramIcon";
import Rounded from "../../../Utils/Rounded";
import "./styles.css";

interface heroSectionProps {
  refContact: React.RefObject<HTMLDivElement>;
}

const HeroSection: React.FC<heroSectionProps> = ({refContact}) => {

  const smoothScroll = () => {
    if (refContact.current !== null) {
      refContact.current.scrollIntoView({
        behavior: "smooth",
      })
    }
  };

  return (
    <>
      <section className="hero-section">
        <ParticlesWSD />
        <div className="container-text">
          <div className="text__wrapper">
            <span className="text-hero-section">
              <strong>WSD</strong> ofrece soluciones de software a la medida, así como consultoría, desarrollo web, aplicaciones móviles usando tecnologías de vanguardia, con la mejor calidad en desarrollo de procesos informáticos.
            </span>
            <span className="text-hero-section">
              <blockquote>“Si lo puedes imaginar, lo podemos crear”</blockquote>
            </span>
          </div>
          <div className="btn-hero-section">
            <ButtonWSD
              handleClick={smoothScroll}
              labelButton={"Contactanos"}
              size="LG"
            />
          </div>
        </div>

        <div className="container-link-hero">
          <Rounded
            styles={{
              backgroundColor: "#fff",
              marginBottom: "1em",
              cursor: "pointer",
            }}
            hover
            children={<FacebookIcon fill="none" stroke="#319DED" />}
            handleClick={() => {}}
          ></Rounded>
          <Rounded
            styles={{
              backgroundColor: "#fff",
              marginBottom: "1em",
              cursor: "pointer",
            }}
            hover
            children={<InstagramIcon fill="none" stroke="#319DED" />}
            handleClick={() => {}}

          ></Rounded>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
