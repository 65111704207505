import { useRef } from "react";

const useRefNavigation = () => {
      const refContact = useRef<any>(null);
      return {
          refContact
      }
};


export default useRefNavigation;