import "./styles.css";
interface titleProps {
  color: string;
  fontSize: string;
  label: string;
  bold?: boolean;
  topLine?: boolean;
  underLine?: boolean;
  id: string;
}

const styles: any = {
    "LG": {
        fontSize: "2.2em"
    },
    "MD": {
        fontSize: "1.8em"
    },
    "navyBlue": {
        color: "#263781"
    },
    "blue": {
        color: "#356CB3"
    },
    "white":  {
        color: "#fff"
    }
}

const Title: React.FC<titleProps> = ({
  color,
  fontSize,
  label,
  bold,
  topLine,
  underLine,
  id
}) => {
  if (underLine) {
    return (
      <div className="container-title-section">
        <span
          className="title-section"
          style={{
            fontSize: styles[fontSize].fontSize,
            fontWeight: bold ? "bold" : "inherit",
            color: styles[color].color,
            paddingBottom: ".2em",
            borderBottom: `2px solid ${styles[color].color}`,
          }}
          id={id}
        >
          {label}
        </span>
      </div>
    );
  } else if (topLine) {
    return (
      <div className="container-title-section">
        <span className="line" style={{backgroundColor: styles[color].color}}></span>
        <span
          className="title-section"
          style={{
            fontSize: styles[fontSize].fontSize,
            fontWeight: bold ? "bold" : "inherit",
            color: styles[color].color,
            
          }}
          id={id}
        >
          {label}
        </span>
      </div>
    );
  }
  return (
    <div className="container-title-section">
      <span
        className="title-section"
        style={{ fontSize: styles[fontSize].fontSize, fontWeight: bold ? "bold" : "inherit", color: styles[color].color }}
        id={id}
      >
        {label}
      </span>
    </div>
  );
};

export default Title;
